import "./styles/App.css";

import { Suspense, lazy } from "react";

const LazyPage = lazy(() => import("./Components/HomePage"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div className="loader"></div>}>
        <LazyPage />
      </Suspense>
    </div>
  );
}

export default App;
